import React from 'react';

import styled from 'styled-components';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import Title from '~/components/Title';
import Img1170x420 from '~/assets/img/banner_sobre.jpg';
import Team from '~/components/Team';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    width: '100%',
    height: '420px'
  },
}));

const Description = withStyles((theme) => ({
  root: {
    color: '#142525',
    fontSize: '22px',
    lineHeight: '1.82',
    fontWeight: 'normal',
    marginTop: '48px',
    marginBottom: '70px',
    whiteSpace: 'pre-wrap'
  },
}))(Typography);

const DivImage = styled.div`
  padding: 0 15px;
  width: 100%;
  height: 420px;
  display: flex;
  border-radius: 8px;
  img {
    border-radius: 8px;
    object-fit: cover;
  }
`;

const Highlight = styled.div`
  width: 100%;
  min-height: 80px;
  border-radius: 40px;
  background-color: #496e6d;

  font-size: 24px;
  font-weight: bold;
  line-height: 1.67;
  color: #ffffff;
  padding: 20px;
  text-align: center;

  margin-bottom: 120px;z
`;

function About () {
  const classes = useStyles();

 
 return <div className={classes.root}>
  <Grid container>
    <Grid item xs={12}>
      <DivImage>
        <img src={Img1170x420} alt="" width="100%" height="100%" />
      </DivImage>
    </Grid>
  </Grid>

  <Grid container direction="row" justify="flex-end" alignItems="center">
    <Grid item xs={1} />
    <Grid item xs={10} style={{marginTop: '80px'}}>
      <Title>Sobre</Title>
      <Description component="div">
        <p>Fundada em 2004, a Neuron é uma clínica multidisciplinar de atendimento em várias subespecialidades Neurológicas e Neurocirúrgicas.</p> 
        <p>O seu corpo clínico é composto por profissionais renomados, constantemente atualizados, e que atuam na formação acadêmica de novos especialistas da área em Pernambuco.</p>
      </Description>
      
      <Highlight>Sempre com a missão de cuidar com excelência, acolhimento e humanização.</Highlight>
    </Grid>
    <Grid item xs={1} />
  </Grid>
  
  <Team parent="about" />

  <Grid container direction="row" justify="flex-end" alignItems="center">
    <Grid item xs={1} />
    <Grid item xs={10}>
      <Title>Planos de Saúde</Title>
      <Description>Entre em contato para se informar se o seu médico aceita o seu plano de sáude.</Description>
    </Grid>
    <Grid item xs={1} />
  </Grid>

</div>
}

export default About;