import { all } from 'redux-saga/effects';

import highlights from './highlights/saga';
import doctors from './doctors/saga';
import contact from './contact/saga';
import scheduling from './scheduling/saga';

export default function* rootSaga() {
  return yield all([highlights, doctors, contact, scheduling]);
}
