import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

import { ReactComponent as WhatsAppIcon } from '~/assets/icons/whatsapp.svg';
import { ReactComponent as NeurocirurgiaIcon } from '~/assets/icons/neurocirurgia.svg';
import { ReactComponent as NeurologiaIcon } from '~/assets/icons/neurologia.svg';
import { ReactComponent as DorOrofacialIcon } from '~/assets/icons/dor_orofacial.svg';
import { ReactComponent as LogoIcon } from '~/assets/icons/logo.svg';
import { ReactComponent as SchedulingIcon } from '~/assets/icons/agendamento.svg';
import { ReactComponent as FacebookIcon } from '~/assets/icons/facebook.svg';
import { ReactComponent as InstagramIcon } from '~/assets/icons/instagram.svg';

const Icon = (props) => {

  let Component = null, viewBox = '';
  switch (props.type) {
    case 'whatsapp':
      Component = WhatsAppIcon;
      viewBox = '0 0 20 20';
      break;
    case 'neurocirurgia':
      Component = NeurocirurgiaIcon;
      viewBox = '0 0 52 44';
      break;
    case 'neurologia':
      Component = NeurologiaIcon;
      viewBox = '0 0 52 44';
      break;
    case 'dor_orofacial':
      Component = DorOrofacialIcon;
      viewBox = '0 0 36 40';
      break;
    case 'logo':
      Component = LogoIcon;
      viewBox = '0 0 175 48';
      break;
    case 'scheduling':
      Component = SchedulingIcon;
      viewBox = '0 0 24 26';
      break;
    case 'facebook':
      Component = FacebookIcon;
      viewBox = '0 0 32 32';
      break;
    case 'instagram':
      Component = InstagramIcon;
      viewBox = '0 0 32 32';
      break;
  
    default:
      break;
  }
  return (
    <SvgIcon component={Component} viewBox={viewBox} {...props} />
  );
}

export default Icon;