import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const Name = withStyles((theme) => ({
  root: {
    color: '#324b4a',
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: theme.spacing(5)
    // marginTop: theme.spacing(2)
  },
}))(Typography);

export default function Text(props) {
  return <Name align="left" component="div">{props.children}</Name>;
}