/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
import React from 'react';

import styled from 'styled-components';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';

const Header = styled(PopoverHeader)`
  background-color: white;
  border-bottom: none;
`;

const Body = styled(PopoverBody)`
  padding: 0 .75rem .75rem .75rem;
`;

const Example = (props) => {

  if (!props.target) {
    return <></>;
  }

  return (
    <div>
      <Popover placement="bottom" isOpen={props.open} target={props.target} toggle={props.toggle}>
        <Header>{props.doctor.name}</Header>
        <Body>
          {props.doctor.crm === '4863 - PE' ? 'CRO:' : 'CRM:'} {props.doctor.crm} {props.doctor.rqe ? ` / RQE: ${props.doctor.rqe}` : null}
          <br /> <br />
          {props.doctor.experience}
        </Body>
      </Popover>
    </div>
  );
}

export default Example;