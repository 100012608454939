import { takeLatest, call, put, all } from "redux-saga/effects";

import api from "~/shared/api";
import toast from '~/components/notification';

export function* postData({ payload }) {
  try {
    const { name, email, phone } = payload.data;

    const date = new Date(payload.data.date),
      day  = date.getDate().toString(),
      fDay = (day.length === 1) ? `0${day}` : day,
      month  = (date.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
      fMonth = (month.length === 1) ? `0${month}` : month,
      fYear = date.getFullYear();

    const fDate = `${fDay}/${fMonth}/${fYear}`;

    let url = `/emails/scheduling.json`;

    yield call(api.post, url, {
      name, email, phone, date: fDate
    });

    toast('Mensagem enviada com sucesso');

    // window.location = '/';
    setTimeout(function(){ window.location = '/'; }, 1500);

  } catch (err) {
    toast('Ocorreu um erro ao enviar a mensagem');
    setTimeout(function(){ window.location = '/'; }, 1500);
    // window.location = '/';
  }
}

export default all([
  takeLatest("@scheduling/sendContact", postData),
]);