import { takeLatest, call, put, all } from "redux-saga/effects";

import api from "~/shared/api";
import { setDoctorsHome, setDoctorsAbout } from '~/store/modules/doctors/actions';

export function* fetchDoctorsHome() {
  try {
    let url = `/doctors/all.json`;

    const { data } = yield call(api.get, url);
    
    yield put(setDoctorsHome(data.doctors));

  } catch (err) {
  }
}

export function* fetchDoctorsAbout() {
  try {
    let url = `/specialties/all.json`;

    const { data } = yield call(api.get, url);

    yield put(setDoctorsAbout(data.specialties));

  } catch (err) {
  }
}

export default all([
  takeLatest("@doctors/fetchDoctorsHome", fetchDoctorsHome),
  takeLatest("@doctors/fetchDoctorsAbout", fetchDoctorsAbout),
]);
