import React from 'react';

import styled from 'styled-components';

const DivImage = styled.div`
  background-color: #F8F7EE; 
  width: 152px !important;
  height: 152px;
  border-radius: 50%;
  border: solid 8px #F8F7EE;
  left: calc(50% - (152px / 2));
  position: relative;
  img {
    border-radius: 50%;
  }
`;

export default function Image(props) {

  return <DivImage>
    <img src={props.src} alt="" width="100%" height="100%" />
  </DivImage>;
}