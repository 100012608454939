import React from 'react';

import styled from 'styled-components';
import { Link, useHistory } from "react-router-dom";

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import Icon from '~/components/Icon';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  footer: {
    width: '100%',
    minHeight: '265px',
  },
  marginBottom: {
    marginBottom: theme.spacing(10)
  }
}));

const FollowText = withStyles((theme) => ({
  root: {
    color: '#263a39',
    fontSize: '21px',
    lineHeight: 'normal',
    fontWeight: 'bold'
  },
}))(Typography);

const RightsText = withStyles((theme) => ({
  root: {
    color: '#263a39',
    fontSize: '14px',
    lineHeight: '1.64',
    fontWeight: 'normal',
    textAlign: 'right',
    marginTop: '13px'
  },
}))(Typography);

const ByText = withStyles((theme) => ({
  root: {
    color: '#91a8a7',
    fontSize: '14px',
    lineHeight: '1.64',
    fontWeight: 'normal',
    textAlign: 'right'
  },
}))(Typography);

const Button = styled.div`
  height:auto;
  width:auto;
  display: inline;
  margin-right: 16px; 
  paddgin-top: 19px; 
  cursor: pointer;
`;

const Line = styled.div`
  width: 100%;
  height: 2px;
  background-color: #5b7d7c;
  margin-right: 4px;
  margin-top: 80px;
  margin-bottom: 26px;
`;

export default function CenteredGrid() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.root}>
      <Grid container className={classes.footer} direction="row">
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Line />
          
          <Grid container className={classes.marginBottom}>  
            <Grid container direction="row" justify="flex-end" alignItems="center" item xs={12} md={6}>
              <Grid item xs={12} md={12}>
                <Box mb={2}> 
                  <FollowText>Siga a Neuron</FollowText>
                </Box>
              </Grid>
              <Grid item xs={12} md={12}>
                  <Button onClick={() => { window.location.href = 'https://www.facebook.com/neuronclinica'}} disableRipple disableFocusRipple><Icon fontSize="large" type="facebook" /></Button>
                  <Button onClick={() => { window.location.href = 'https://www.instagram.com/neuronclinica'}} disableRipple disableFocusRipple><Icon fontSize="large" type="instagram" /></Button>
              </Grid>
            </Grid>
            
            <Grid item xs={12} md={6}>
              <Box
                xs={{mb: 5}}
              >
                <RightsText>© 2020. +Derme. Todos os Direitos Reservados.</RightsText>
                <ByText>Desenvolvido por Querosene</ByText>
              </Box>
            </Grid>
          </Grid>

        </Grid>
        <Grid item xs={1} />
      </Grid>
    </div>
  );
}
