import React from "react";

import clsx from "clsx";

import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';

import styled from "styled-components";
import { TextField } from "formik-material-ui";
import { Field } from "formik";

const Component = styled(Field)``;

const useStyles = makeStyles((theme) => ({
  root: {
    color: 'black !important',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  underline: {
    color: '#f8f7ee',
    transform: 'scaleX(1)',
    '&:after': {
      borderBottom: '2px solid #d9d499',
    },
    '&:before': {
      borderBottom: '2px solid #d9d499',
    },
    '&:hover:not(.Mui-disabled):before': {
      borderBottom: '2px solid #d9d499',
    },
  },
}));

const Label = withStyles({
  root: {
    color: 'black',
    fontSize: '14px'
  },
})(InputLabel);

const Text = props => {
  const classes = useStyles();
  const attr = {...props};

  let label = null;
  if (attr.label) {
      label = <Label htmlFor={props.id}>{attr.label}</Label>;
      attr.label = false;
  }

  const inputStyle = { };
  const InputProps = {
    className: clsx(classes.root, classes.underline),
    inputComponent: attr.inputComponent
  }

  return <React.Fragment>
    {label}
    <Component 
      {...attr} 
      component={TextField} 
      className={clsx({[classes.textarea]: attr.multiline, ...attr.className})} 
      InputProps={InputProps} 
      inputProps={{style: inputStyle}}
    >
      {attr.children}
    </Component>
  </React.Fragment>;
} 

export default Text;
