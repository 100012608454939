import React from 'react';

import styled from 'styled-components';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
}));

const Line = styled.div`
  width: 40px;
  height: 4px;
  background-color: #d9d499;
  margin-right: 4px;
  margin-bottom: 20px;
`;

const Dot = styled.div`
  width: 4px;
  height: 4px;
  background-color: #d9d499;
`;

export default function Title(props) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Line /><Dot />
      </div>
      <Typography variant="h4" color="primary" style={{fontWeight: 'bold'}}>{props.children}</Typography>
    </React.Fragment>
  );
}
