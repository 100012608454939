import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import Icon from '~/components/Icon';

const Circle = withStyles({
  root: {
    height: '70px',
    width: '70px',
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: '50px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#324b4a',
    padding: '6px 22px',
    border: '1px solid',
    backgroundColor: '#eceacc',
    borderColor: '#eceacc',
    borderRadius: '50%',
    cursor: 'default',
    marginRight: '16px',
    '&:hover': {
      backgroundColor: '#eceacc',
    },
    '&:active': {
      backgroundColor: '#eceacc',
    },
  },
})(Button);

export default function cirleIcon (props) {
  return <Circle>
    <Icon type={props.type} fontSize="inherit" />
  </Circle>
}