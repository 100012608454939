import produce from 'immer';

const INITIAL_STATE = {
  itemsHome: [],
  itemsAbout: [],
};

export default function reducer(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@doctors/setDoctorsHome': {
        draft.itemsHome = action.payload.items;
        break;
      }
      case '@doctors/setDoctorsAbout': {
        draft.itemsAbout = action.payload.items;
        break;
      }
      default:
    }
  });
}
