import { createGlobalStyle } from 'styled-components';

import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-toastify/dist/ReactToastify.css';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  *:focus {
    outline:0;
  }

  html, body, #root {
    height: 100%;
    background: #F3F5F8;
  }

  body {
    -webkit-font-smoothing: antialiased;
    background: #F3F5F8;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  button {
    cursor: pointer;
  }

  // Datepicker customization
  .rdrMonthAndYearWrapper {
    background-color: #F8F8F8;
    height: 90px !important;  
  }

  // Reactstrap Popover customization
  .bs-popover-auto[x-placement^=bottom]>.arrow::before, .bs-popover-bottom>.arrow::before {
      border-bottom-color: #bfb74b;
  }
  .popover {
    border: 1px solid #bfb74b;
    top: 4px !important;
  }

  // toastfy
  .Toastify__toast--default {
    background: #d9d499;
    color: #485f60;
  }

  .slick-dots {
    bottom: 0 !important;
  }
`;
