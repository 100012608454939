import React from 'react';

import styled from 'styled-components';
import clsx from 'clsx';
import { useHistory } from "react-router-dom";
import { NavHashLink as Link } from 'react-router-hash-link';

import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Hidden from '@material-ui/core/Hidden';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import Button from '~/components/Button';
import Icon from '~/components/Icon';
import LogoSvg from '~/assets/icons/logo.svg';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    backgroundColor: '#f8f7ee',
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
}));

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    height: '120px'
  }
}));

const useTypographStyles = makeStyles((theme) => ({
  root: {
    fontSize: '14px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.main,
    }
  },
  active: {
    color: '#a19946'
  }
}));

const useAppbarStyles = makeStyles((theme) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  colorPrimary: {
    backgroundColor: '#FFFFFF',
    color: theme.palette.primary.main
  },
}));

const Logo = styled.img`
  cursor: pointer;
  margin-left: 15px;
  height: 48px;
`;

const WhiteListItem = withStyles((theme) => ({
  root: {
    backgroundColor: '#f8f7ee',
  },
}))(ListItem);

const DarkListItem = withStyles((theme) => ({
  root: {
    backgroundColor: '#d9d499',
  },
}))(ListItem);

export default function PersistentDrawerRight() {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const appBarClasses = useAppbarStyles();
  const toolBarClasses = useToolbarStyles();
  const typographClasses = useTypographStyles();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const scrollWithOffset = (el, offset) => {
    const elementPosition = el.offsetTop - offset;
    window.scroll({
      top: elementPosition,
      left: 0,
      behavior: "smooth"
    });
  }

  return (
    <div className={classes.root}>
      <AppBar
        elevation={0}
        position="fixed"
        className={clsx(appBarClasses.appBar, appBarClasses.colorPrimary, {
          [appBarClasses.appBarShift]: open,
        })}
      >
        <Container maxWidth="lg" disableGutters={true}>
          <Toolbar className={clsx(toolBarClasses.root)} disableGutters>
            <Grid container item sm={6} lg={6} direction="row" justify="flex-start" alignItems="center">
              <Logo disableRipple onClick={() => history.push("/")} src={LogoSvg} />
            </Grid>

            <Hidden smDown>
              <Grid container item sm={6} lg={6} direction="row" justify="space-between" alignItems="center">
                <Grid>
                  <Link activeClassName={typographClasses.active} className={typographClasses.root} to="/sobre">
                    SOBRE
                  </Link>
                </Grid>
                {/* <Grid>
                  <Link activeClassName={typographClasses.active}
                    className={typographClasses.root} 
                    to="/tratamentos#t"
                    scroll={el => scrollWithOffset(el, 150)}
                    >
                    TRATAMENTOS
                  </Link>
                </Grid> */}
                <Grid>
                  <Link activeClassName={typographClasses.active} className={typographClasses.root} to="/especialidades">
                    ESPECIALIDADES
                  </Link>
                </Grid>
                <Grid>
                  <Link activeClassName={typographClasses.active} className={typographClasses.root} to="/contato">
                    CONTATO
                  </Link>
                </Grid>
                <Grid>
                  <Button onClick={() => { window.location.href = 'https://api.whatsapp.com/send/?phone=5581981059248'; handleDrawerClose(); }} style={{ marginRight: '15px' }} startIcon={<Icon type="whatsapp" style={{ fontWeight: 'bold' }} />}>81 98105.9248</Button>
                </Grid>
              </Grid>
            </Hidden>

            <Hidden mdUp>
              <Grid container item sm={8} direction="row" justify="flex-end" alignItems="center">
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="end"
                  onClick={handleDrawerOpen}
                  className={clsx(open && classes.hide)}
                  style={{ marginRight: 0 }}
                >
                  <MenuIcon fontSize="large" />
                </IconButton>
              </Grid>
            </Hidden>
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer
        className={classes.drawer}
        anchor="right"
        open={open}
        onClose={handleDrawerClose}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <List>
          <WhiteListItem button key={'SOBRE'} onClick={handleDrawerClose}>
            <Link activeClassName={typographClasses.active} to="/sobre" className={typographClasses.root}>
              <ListItemText primary={'SOBRE'} />
            </Link>
          </WhiteListItem>
          {/* <WhiteListItem button key={'TRATAMENTOS'} onClick={handleDrawerClose}>
            <Link 
              to="/tratamentos#t"
              scroll={el => scrollWithOffset(el, 150)}
              className={typographClasses.root}>
              <ListItemText primary={'TRATAMENTOS'} />
            </Link>
          </WhiteListItem> */}
          <WhiteListItem button key={'ESPECIALIDADES'} onClick={handleDrawerClose}>
            <Link activeClassName={typographClasses.active} to="/especialidades" className={typographClasses.root} >
              <ListItemText primary={'ESPECIALIDADES'} />
            </Link>
          </WhiteListItem>
          <WhiteListItem button key={'CONTATO'} onClick={handleDrawerClose}>
            <Link activeClassName={typographClasses.active} to="/contato" className={typographClasses.root} >
              <ListItemText primary={'CONTATO'} />
            </Link>
          </WhiteListItem>
          <DarkListItem button key={'WHATSAPP'} onClick={() => { window.location.href = 'https://api.whatsapp.com/send?phone=5581981059248'; handleDrawerClose(); }}>
            <ListItemIcon>
              <Icon type="whatsapp" style={{ fontWeight: 'bold' }} />
            </ListItemIcon>
            <ListItemText primary={'81 98105.9248'} className={typographClasses.root} />
          </DarkListItem>
        </List>
      </Drawer>
    </div>
  );
}
