import React from "react";
import PropTypes from "prop-types";
import styled from 'styled-components';

import { Helmet } from 'react-helmet'

import Menu from "../Menu";
import { makeStyles } from "@material-ui/core/styles";
import Container from '@material-ui/core/Container';
import Schedule from '~/components/Schedule';
import Footer from '~/components/Footer';

const Highlight = styled.div`
  width: 100%;
  min-height: 80px;
  border-radius: 40px;
  background-color: #496e6d;

  font-size: 24px;
  font-weight: bold;
  line-height: 1.67;
  color: #ffffff;
  padding: 20px;
  text-align: center;
  
  margin-top: 120px;
  margin-bottom: 80px;
`;

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white'
  },
  container: {
    height: '100%',
    marginTop: '120px'
  },
  marginTop: {
  }
}));

export default function DefaultLayout({ children, title }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Helmet><title>{`Neuron | ${title}`}</title></Helmet>
      <Menu />
      <Container maxWidth="lg" disableGutters={true} className={classes.container}>
        {children}
        <Schedule />
        {
          window.location.href.includes('contato') ?
            <Highlight>Para mais informações: 81 3221.0605, 81 3222.7763 ou 81 98139.2157</Highlight>
            : null
        }
        <Footer />
      </Container>
    </div>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
