import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const Name = withStyles((theme) => ({
  root: {
    color: '#324b4a',
    fontSize: '15px',
    lineHeight: 'normal',
    fontWeight: 'normal',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: '0 5px',
    height: '38px'
  },
}))(Typography);

export default function Text(props) {
  return <Name align="center" component="div" {...props}>{props.children}</Name>;
}