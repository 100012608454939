import React from 'react';

import * as Yup from 'yup';
import { useDispatch } from 'react-redux';

import PhoneMask from '~/components/Mask/Phone';
import Form from '~/components/Form';
import { sendContact } from '~/store/modules/scheduling/actions';

const schema = Yup.object().shape({
  name: Yup.string()
    .required('O nome é obrigatório'),
  date: Yup.string()
    .typeError('A data é obrigatória')
    .required('A data é obrigatória'),
  phone: Yup.string()
    .required('O telefone é obrigatório'),
  email: Yup.string()
    .email('Insira um e-mail válido')
    .required('O e-mail é obrigatório'),
});

const fields = [
  {
    id: 'name',
    name: 'name',
    label: 'Name',
  },
  {
    id: 'date',
    name: 'date',
    label: 'Data',
    type:'datePicker',
  },
  {
    id: 'email',
    name: 'email',
    label: 'Email',
  },
  {
    id: 'phone',
    name: 'phone',
    label: 'Phone',
    inputComponent: PhoneMask,
  },
];

export default function CenteredGrid() {
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    dispatch(sendContact(data));
  }

  return <Form 
    type="scheduling"
    title="Agendamento"
    schema={schema}
    fields={fields}
    onSubmit={onSubmit}
    initialValues={{name: '', date: null, email: '', phone: ''}}
    submitButtonLabel="AGENDAR"
  />;
}
