import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const DefaultButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: '14px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#485f60',
    padding: '6px 16px',
    border: '1px solid',
    backgroundColor: '#d9d499',
    borderColor: '#d9d499',
    borderRadius: '18px',
    '&:hover': {
      backgroundColor: '#d9d499',
    },
    '&:hover.Mui-disabled': {
      backgroundColor: '#d9d499',
    },
    '&:active': {
      backgroundColor: '#d9d499',
    },
  },
})(Button);

const TeamButton = withStyles({
  root: {
    height: '24px',
    width: '24px',
    minWidth: '24px',
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: '23px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: 'white',
    border: '1px solid',
    backgroundColor: '#bfb653',
    borderColor: '#bfb653',
    borderRadius: '50%',
    left: 'calc(50% - (24px / 2))',
    bottom: '77px',
    '&:hover': {
      backgroundColor: '#bfb653',
    },
    '&:active': {
      backgroundColor: '#bfb653',
    },
  },
})(Button);

export default function CustomButton(props) {
  let Button = DefaultButton;
  if (props.type === 'team') {
    Button = TeamButton;
  }

  return <Button {...props}>
    {props.children}
  </Button>;
}
