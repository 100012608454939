import { takeLatest, call, put, all } from "redux-saga/effects";

import api from "~/shared/api";
import toast from '~/components/notification';

export function* postData({ payload }) {
  try {
    let url = `/emails/contact.json`;

    yield call(api.post, url, payload.data);

    toast('Mensagem enviada com sucesso');

    setTimeout(function(){ window.location = '/'; }, 1500);

  } catch (err) {
    toast('Ocorreu um erro ao enviar a mensagem');
    setTimeout(function(){ window.location = '/'; }, 1500);
    // window.location = '/';
  }
}

export default all([
  takeLatest("@contact/sendContact", postData),
]);