import React from 'react';

import clsx from 'clsx';

import styled from 'styled-components';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import MagicSliderDots from 'react-magic-slider-dots';
import 'react-magic-slider-dots/dist/magic-dots.css';

import Hidden from '@material-ui/core/Hidden';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';

import SpecialtyNameHome from '~/components/Team/SpecialtyNameHome';
import DoctorName from '~/components/Team/DoctorName';
import Img136x136 from '~/assets/img/136x136.png';
import Image from '~/components/Team/Image';
import Button from '~/components/Button';
import { BASE_URL } from '~/shared/api';

const DivTeamMate = styled.div`
  max-width: 170px;
  height: auto;
  display: flex;
  left: calc(50% - (170px / 2));
  position: relative;
`;

const useStyles = makeStyles((theme) => ({
  marginTop: {
    marginTop: '49px'
  },
  marginBottom: {
    marginBottom: '80px'
  },
}));

export default function Mobile(props) {
  const classes = useStyles();
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    onSwipe: props.onSwipe,
    appendDots: dots => {
      return <MagicSliderDots dots={dots} numDotsToShow={4} dotWidth={30} />;
    }
  };

return <Hidden mdUp> 
  <Slider {...settings} className={clsx({[classes.marginTop]: props.parent !== 'about'}, classes.marginBottom)}>
    {
      !props.doctors ? null :
      props.doctors.map((doctor, key) => {
        return <DivTeamMate key={key}>
          <Image src={`${BASE_URL}/upload/doctors/image/${doctor.image_dir}/${doctor.image}`} />
          <DoctorName style={{marginBottom: props.parent === 'about' ? 0 : '16px'}}>{doctor.name}</DoctorName>
          {props.parent !== 'about' ? <SpecialtyNameHome style={{marginBottom: '10px'}}>{doctor.specialty.name}</SpecialtyNameHome> : null}
          {props.parent === 'about' ? <Button type="team" onClick={(event) => props.handleClick(event, doctor)}><AddIcon /></Button> : null}
        </DivTeamMate>
      })
    }
  </Slider>
</Hidden>
}