export function fetchDoctorsHome() {
  return {
    type: '@doctors/fetchDoctorsHome',
  };
}

export function setDoctorsHome(items) {
  return {
    type: '@doctors/setDoctorsHome',
    payload: {items}
  };
}

export function fetchDoctorsAbout() {
  return {
    type: '@doctors/fetchDoctorsAbout',
  };
}

export function setDoctorsAbout(items) {
  return {
    type: '@doctors/setDoctorsAbout',
    payload: {items}
  };
}