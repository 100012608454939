import React, { useEffect } from 'react';


import Highlights from '~/components/Home/Highlights';
import About from '~/components/Home/About';
import Specialties from '~/components/Home/Specialties';
import Team from '~/components/Home/Team';


function Home () {


  return <React.Fragment>
    <Highlights />
    <About />
    <Specialties />
    <Team />
  </React.Fragment>;
}

export default Home;