import React from 'react';

import { Formik, Form } from "formik";
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

import Title from '~/components/Title';
import Button from '~/components/Button';
import Input from '~/components/Input/Text';
import DatePicker from '~/components/Input/DatePicker';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    minHeight: '415px',
  },
  contact: {
    backgroundColor: 'transparent',
  },
  scheduling: {
    backgroundColor: '#f8f7ee',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    width: '100%',
    height: '420px'
  },
  container: {
    marginTop: '80px',
    marginBottom: '80px'
  },
  button: {
    float: 'right',
    marginTop: '17px'
  },
  inputItem: {
    marginTop: '40px'
  }
}));

export default function ContactForm(props) {
  const classes = useStyles();
  
  return <Formik
      initialValues={props.initialValues}
      validationSchema={props.schema}
      onSubmit={props.onSubmit}
    >
      {({ isSubmitting, dirty }) => (
        <React.Fragment>
            <Form style={{display: 'contents'}}>
              <div className={clsx(classes.root, {[classes.contact]: props.type === 'contact', [classes.scheduling]: props.type === 'scheduling'})}>
                <Grid container direction="row">
                  <Grid item xs={1} />
                  <Grid item xs={10}>
                    <Grid container direction="row" alignItems="center" className={classes.container}>
                      <Grid container>
                        <Grid item xs={8}>
                          <Title>{props.title}</Title>
                        </Grid>
                        <Hidden only="xs">
                          <Grid item xs={4}>
                            <Button type="submit" disabled={isSubmitting || !dirty} className={classes.button}>{isSubmitting ? 'Aguarde' : props.submitButtonLabel}</Button>
                          </Grid>
                        </Hidden>
                      </Grid>

                      <Grid container spacing={3}>

                        { props.fields.length && props.fields.map((field, key) => {
                          if (field.type === 'datePicker') {
                            return <Grid key={key} item xs={6} className={classes.inputItem}>
                              <DatePicker 
                                id={field.id}
                                name={field.name}
                                label={field.label}
                                format="dd/MM/yyyy"
                                disabled={ isSubmitting }
                                fullWidth
                              /> 
                            </Grid>
                          }

                          return <Grid key={key} item xs={field.id === 'message' ? 12 : 6} className={classes.inputItem}>
                            <Input 
                              id={field.id}
                              name={field.name}
                              label={field.label}
                              disabled={ isSubmitting }
                              inputComponent={field.inputComponent}
                              fullWidth
                            /> 
                          </Grid>
                        }) }

                        <Hidden smUp>
                          <Grid item xs={4}>
                            <Button type="submit" disabled={isSubmitting || !dirty} style={{float: 'left'}} className={classes.button}>{isSubmitting ? 'Aguarde' : props.submitButtonLabel}</Button>
                          </Grid>
                        </Hidden>
                      </Grid>
                      
                    </Grid>
                  </Grid>
                  <Grid item xs={1} />
                </Grid>
              </div>
            </Form>
        </React.Fragment>
      )}
    </Formik>
}
