import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const Name = withStyles((theme) => ({
  root: {
    color: '#A19946',
    fontSize: '14px',
    // marginBottom: theme.spacing(5)
    // marginTop: theme.spacing(2)
  },
}))(Typography);

export default function Text(props) {
  return <Name align="center" component="div" {...props}>{props.children}</Name>;
}