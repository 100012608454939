import React, { useEffect } from 'react';

import styled from 'styled-components';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Box from '@material-ui/core/Box';
import { HashLink as Link } from 'react-router-hash-link';

import Title from '~/components/Title';
import Img136x136 from '~/assets/img/136x136.png';
import Image from '~/components/Team/Image';
import DoctorName from '~/components/Team/DoctorName';
import SpecialtyNameHome from '~/components/Team/SpecialtyNameHome';
import Mobile from '~/components/Team/Mobile';
import { fetchDoctorsHome } from '~/store/modules/doctors/actions';
import { BASE_URL } from '~/shared/api';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  marginTop: {
    marginTop: '49px'
  },
  marginBottom: {
    marginBottom: '80px'
  },
  centerBox: {
    left: 'calc(50% - (152px / 2))'
  }
}));

const scrollWithOffset = (el, offset) => {
  const elementPosition = el.offsetTop - offset;
  window.scroll({
    top: elementPosition,
    left: 0,
    behavior: "smooth"
  });    
}

export default function CenteredGrid() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const doctors = useSelector(state => state.doctors.itemsHome);

  useEffect(() => {
    dispatch(fetchDoctorsHome());
  }, []);

  return (
    <div className={classes.root}>
      <Grid container direction="row">
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Grid container direction="row" alignItems="center" className={classes.marginTop}>
            <Grid item xs={8}>
              <Title>Quem faz</Title>
            </Grid>
            <Grid item xs={4}>
              <Link to="/sobre#equipe" scroll={el => scrollWithOffset(el, 150)} underline="always" style={{float: 'right', color: '#485f60', fontSize: '14px', textDecoration: 'underline'}}>
                ver toda equipe</Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} />
      </Grid>

      <Mobile doctors={doctors} />
      
      <Hidden smDown>
        <Grid container className={clsx(classes.marginTop, classes.marginBottom)}>
          <Grid item md={1} />
          {
            !doctors.length ? null :
            doctors.map((doctor, key) => {
              return <Grid item md={2} key={key}>
                <Box className={classes.centerBox}>
                  <Image src={`${BASE_URL}/upload/doctors/image/${doctor.image_dir}/${doctor.image}`}/>
                </Box>
                <DoctorName>{doctor.name}</DoctorName>
                <SpecialtyNameHome>{doctor.specialty.name}</SpecialtyNameHome>
              </Grid>
            })
          }
          <Grid item md={1} />
        </Grid>
      </Hidden>
    </div>
  );
}
