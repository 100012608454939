import React from "react";

import { Switch, Redirect } from "react-router-dom";

import Route from "./Route";
import Home from "~/containers/Home";
import Specialties from "~/containers/Specialties";
import Contact from "~/containers/Contact";
import About from "~/containers/About";
import ScrollToTop from "~/components/ScrollToTop";

export default function Routes() {
  return (
    <ScrollToTop>
      <Switch>
        <Route path="/tratamentos" name="Tratamentos" component={Specialties} />
        <Route path="/especialidades" name="Especialidades" component={Specialties} />
        <Route path="/contato" name="Contato" component={Contact} />
        <Route path="/sobre" name="Sobre" component={About} />
        <Route path="/" name="Home" exact component={Home} />
        <Route path='/facebook' component={() => { 
          window.location.href = 'https://www.facebook.com/neuronclinica'; 
          return null;
        }}/>
        <Route path='/instagram' component={() => { 
          window.location.href = 'https://www.instagram.com/neuronclinica'; 
          return null;
        }}/>
        <Route path='/whatsApp' component={() => { 
          window.location.href = 'https://api.whatsapp.com/send/?phone=5581981059248'; 
          return null;
        }}/>
        <Route component={<>Deu ruim</>} name="404" path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </ScrollToTop>
  );
}
