import React, { useEffect } from 'react';

import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

import { BASE_URL } from '~/shared/api';
import { fetchHighlights } from '~/store/modules/highlights/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    width: '100%',
    height: '420px'
  },
}));

const DivImage = styled.div`
  background-color: #F8F7EE; 
  width: 100%;
  height: 420px;
  display: flex;
  img {
    object-fit: cover;
  }
`;

export default function CenteredGrid(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const highlights = useSelector(state => state.highlights.items);

  useEffect(() => {
    dispatch(fetchHighlights());
  }, []);

  return (
    !highlights.length ? null :
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <DivImage>
            <Hidden smDown>
              <img src={`${BASE_URL}/upload/highlights/image/${highlights[0].image_dir}/${highlights[0].image}`} alt={highlights[0].name} width="100%" height="100%" />
            </Hidden>
            <Hidden mdUp>
              <img src={`${BASE_URL}/upload/highlights/image_mobile/${highlights[0].image_dir_mobile}/${highlights[0].image_mobile}`} alt={highlights[0].name} width="100%" height="100%" />
            </Hidden>
          </DivImage>
        </Grid>
      </Grid>
    </div>
  );
}
